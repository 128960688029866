import { createSlice } from '@reduxjs/toolkit';

const wishlistLocalLength = createSlice({
  name: 'wishlist',
  initialState: {
    localWishlistLength: 0, // Tracks the number of items in the local cart
    wishlistdata:[],
  },
  reducers: {
    updateLocalWishlistLength: (state, action) => {
      state.localWishlistLength = action.payload; // Update the cart length
    },
    setWishlist: (state, action) => {
      state.wishlistdata = action.payload; 
    },
  },
});

export const { updateLocalWishlistLength,setWishlist} = wishlistLocalLength.actions;
export default wishlistLocalLength.reducer;