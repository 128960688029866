import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import {Col, Form, Input, Row, Button, Divider, message} from "antd";
import {EnvironmentOutlined, PhoneOutlined, MailOutlined, GlobalOutlined, SkinOutlined} from "@ant-design/icons";
import Logo from '../../Assets/Images/OneWear/OneWear logo.png';
import image1 from '../../Assets/Images/OneWear/OneWearInsta.svg';
import image2 from '../../Assets/Images/OneWear/OneWearLinkedIn.svg';
import image3 from '../../Assets/Images/OneWear/OneWearTwitter.svg';
import EcdigiLogo from '../../Assets/Images/EcdigiWhite.png';
import '../../Assets/Fonts/fonts.css';
import API from "../../ApiService/ApiService";
import { useSelector } from "react-redux";
import LazyImage from "../../Ecommerce/IntersectionObserver";


const Footer12 = ({content_all}) => {
  // console.log("Footer12content_all", content_all);
  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  const api = new API();

  const contactData = useSelector((state) => state?.contactus?.contactus);
  // console.log("contactData", contactData);
  const company = useSelector((state) => state?.company?.company?.data[0]);  
  // console.log("company", company);
  const activeContactUsFortheCompayCode = contactData?.data?.find((x) => x?.company_code == company?.company_code && x?.status == true);

  // console.log("activeContactUsFortheCompayCode", activeContactUsFortheCompayCode);
  return (
    <FooterAlign>
    <div className="home_wrapper">
      <div className="Footer_Section">
        <div className="Footer_Top">
          <div className='Footer_Top_logo'>
          <div 
            className="Footer_Top1" 
            // style={{display: "flex", flexDirection: "column", alignItems: "center"}}
            >
              <div className="Logo_Image">
                <LazyImage src={process.env.REACT_APP_API_BASE + content_all?.onewear_footer_logo?.image} alt="logo" />
              </div>
              <div className="content">                
              </div>
              <div className="Online_Card">
                {content_all?.onewear_social_media?.map((item, index) => (                
                <div className='Online' style={{marginTop: "0.5rem"}}>
                    <a href={item?.onewear_media_link} style={{textDecoration: "none", color: "white"}}>
                      <LazyImage src={process.env.REACT_APP_API_BASE + item?.onewear_media_img?.image} alt="logo" />
                    </a>
                </div>
                ))}                
              </div>
            </div>
          </div>
          <div className="Footer_Top_Main">                        
            <div className="Footer_Top3">
              <p className="Footer_Top3_title" style={{marginBottom: "0rem"}}>Help</p>
              <ul className="Footer_Top3_links">
                <li>
                  <Link
                    to="/my-profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    My Account
                  </Link>
                </li>  
                {login && token && userData && <li>
                  <Link
                    to="/my-order"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Track Your Order
                  </Link>
                </li>}  
                {!login && !token && !userData && <li>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Track Your Order
                  </Link>
                </li>}  
                <li>
                    <Link to="/return-policy" style={{textDecoration: "none", color: "#000"}}>
                      Exchange/Return
                    </Link>
                </li>             
                {/* <li>
                    <Link to="/privacy-policy" style={{textDecoration: "none", color: "#000"}}>
                    Privacy Policy
                    </Link>
                    </li> */}
                {/* <li>
                    <Link to="/terms-and-condition" style={{textDecoration: "none", color: "#000"}}>
                    Terms And Condition
                    </Link>
                     </li> */}
                {/* <li>
                    <Link to="/cancellation-policy" style={{textDecoration: "none", color: "#000"}}>
                    Cancellation Policy
                    </Link>
                     </li> */}
                {/* <li>
                    <Link to="/return-policy" style={{textDecoration: "none", color: "#000"}}>
                    Return Policy
                    </Link>
                     </li> */}
                {/* <li>
                    <Link to="/refund-policy" style={{textDecoration: "none", color: "#000"}}>
                    Refund & Return Policy
                    </Link>
                    </li> */}
                    {/* <li>
                    <Link to="/delivery-policy" style={{textDecoration: "none", color: "#000"}}>
                    Delivery Policy
                    </Link>
                    </li> */}
              </ul>
            </div>           
            {/* <div className="Footer_Top3" hidden={!login && !token && !userData}> */}
            <div className="Footer_Top3" >
              <p className="Footer_Top3_title" style={{marginBottom: "0rem"}}>Terms & Conditions</p>
              <ul className="Footer_Top3_links">
                <li>
                    <Link to="/terms-and-condition" style={{textDecoration: "none", color: "#000"}}>
                      Terms of Service
                    </Link>
                </li>
                <li>
                    <Link to="/privacy-policy" style={{textDecoration: "none", color: "#000"}}>
                    Privacy Policy
                    </Link>
                </li>
                <li>
                    <Link to="/cancellation-policy" style={{textDecoration: "none", color: "#000"}}>
                      Cancellation Policy
                    </Link>
                </li>
                <li>
                    <Link to="/refund-policy" style={{textDecoration: "none", color: "#000"}}>
                      Refund & Return Policy
                    </Link>
                </li>
                <li>
                    <Link to="/delivery-policy" style={{textDecoration: "none", color: "#000"}}>
                      Delivery Policy
                    </Link>
                </li>
                {/* <li>
                  <Link
                    to="/my-profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    My Profile
                  </Link>
                </li> */}
                {/* <li>
                <Link
                  to="/delivery-address"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Delivery Address
                </Link>
                </li> */}
                {/* <li>
                <Link
                  to="/change-password"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Change Password
                </Link>
                     </li> */}
                {/* <li>
                <Link
                  to="/my-order"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Track Your Order
                </Link>
                     </li> */}
                {/* <li>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Our Story
                </Link>
                     </li>   */}
                     {/* <li>
                    <Link to="/contact" style={{textDecoration: "none", color: "#000"}}>
                    Contact us
                    </Link> 
                    </li>              */}
              </ul>
            </div>
            <div 
            className="Footer_Top1" 
            // style={{display: "flex", flexDirection: "column", alignItems: "center"}}
            style={{display: "none"}}
            >
              <div className="Logo_Image">
                {/* <Link to="/"> */}
                {/* <img src={company?.logo ? base_url + company?.logo : require("../../Assets/Images/logo.png")} alt="logo" /> */}
                <LazyImage src={process.env.REACT_APP_API_BASE + content_all?.onewear_footer_logo?.image} alt="logo" />
                {/* <img src={Logo} alt="" /> */}
                {/* </Link> */}
              </div>
              <div className="content">
                {/* <p style={{marginTop: "1rem"}}>
                  {content_all?.onewear_footer_description}
                </p> */}
                {/* <p>
                  An online gift shop for unique, funny and creative gifts for
                  guys and girls. At bigsmall.in, explore the most unique
                  gifts for any kind of person, or occasion.
                </p> */}
              </div>
              <div className="Online_Card">
                {content_all?.onewear_social_media?.map((item, index) => (                
                <div className='Online' style={{marginTop: "0.5rem"}}>
                    {/* <Link to={item?.onewear_media_link} style={{textDecoration: "none", color: "white"}}> */}
                    <a href={item?.onewear_media_link} style={{textDecoration: "none", color: "white"}}>
                      <LazyImage src={process.env.REACT_APP_API_BASE + item?.onewear_media_img?.image} alt={item?.onewear_media_name} />
                    </a>
                    {/* </Link> */}
                </div>
                ))}
                {/* <div className='Online'>
                    <Link to={content_all?.onewear_footer_lin_link} style={{textDecoration: "none", color: "white"}}>
                      <img src={image2} alt="" />
                    </Link>
                </div>
                <div className='Online'>
                    <Link to={content_all?.onewear_footer_x_link} style={{textDecoration: "none", color: "white"}}>
                      <img src={image3} alt="" />
                    </Link>
                </div> */}
              </div>
            </div>            
            <div className="Footer_Top3">
              <p className="Footer_Top3_title" style={{marginBottom: "0rem"}}>Company</p>
              <ul className="Footer_Top3_links" style={{marginBottom: "-0.2rem"}}>
                <li>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <SkinOutlined /> Our Brand
                  </Link>
                </li>  
                <li>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <GlobalOutlined rotate={90} style={{verticalAlign: "-0.2rem"}} /> Contact Us
                  </Link>
                </li>  
                <li>
                  <Link
                    // to="/contact"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <PhoneOutlined rotate={90} style={{verticalAlign: "-0.2rem"}} />&nbsp; 
                    {/* <div> */}
                      {activeContactUsFortheCompayCode?.mobile_number ? (
                        <a
                          href={`tel:${activeContactUsFortheCompayCode.mobile_number}`}                          
                          // href="tel:+912244550284"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          +91{activeContactUsFortheCompayCode.mobile_number}, {company?.mobile_number}
                          {/* +91 22 4455 0284 */}
                        </a>
                      ) : (
                        <p>Phone number not available</p>
                      )}
                    {/* </div> */}
                  </Link>
                </li>  
                <li>
                  <Link
                    // to="/contact"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MailOutlined style={{verticalAlign: "-0.2rem"}} />&nbsp;
                    {/* <div> */}
                      {activeContactUsFortheCompayCode?.email ? (
                        <a
                          href={`mailto:${activeContactUsFortheCompayCode.email}`}
                          style={{ color: "black", textDecoration: "none", wordBreak: "break-all" }}
                        >
                          {activeContactUsFortheCompayCode.email}
                        </a>
                      ) : (
                        <p style={{ color: "black", textDecoration: "none", wordBreak: "break-all" }}>Email not available</p>
                      )}
                    {/* </div> */}
                  </Link>
                </li>  
                <li>
                <Col span={24} className="icons_align">
                  <div className="icon">
                    <EnvironmentOutlined />
                  </div>
                  <div className="address">
                    <p style={{ marginBottom: "0px" }}>
                      {content_all?.onewear_footer_address
                        ? `${content_all?.onewear_footer_address},`
                        : "Address not available"}
                      <br />
                      {content_all?.onewear_footer_city &&
                      // activeContactUsFortheCompayCode?.district &&
                      content_all?.onewear_footer_state &&
                      content_all?.onewear_footer_pincode
                        ? `${content_all?.onewear_footer_city}, ${content_all?.onewear_footer_state} - ${content_all?.onewear_footer_pincode}`
                        : "Location details not available"}
                    </p>
                  </div>
                  {/* <div className="address">
                    <p style={{ marginBottom: "0px" }}>
                      {activeContactUsFortheCompayCode?.addressline_1 && activeContactUsFortheCompayCode?.addressline_2
                        ? `${activeContactUsFortheCompayCode?.addressline_1}, ${activeContactUsFortheCompayCode?.addressline_2}`
                        : "Address not available"}
                      <br />
                      {activeContactUsFortheCompayCode?.city &&
                      activeContactUsFortheCompayCode?.district &&
                      activeContactUsFortheCompayCode?.state &&
                      activeContactUsFortheCompayCode?.pincode
                        ? `${activeContactUsFortheCompayCode?.city}, ${activeContactUsFortheCompayCode?.district.trim()}, ${activeContactUsFortheCompayCode?.state} - ${activeContactUsFortheCompayCode?.pincode}`
                        : "Location details not available"}
                    </p>
                  </div> */}
                </Col>
                </li>                
              </ul>
              <Row gutter={[16, 16]}>
                {/* Address Section */}
                {/* <Col span={24} className="icons_align">
                  <div className="icon">
                    <EnvironmentOutlined />
                  </div>
                  <div className="address">
                    <p style={{ marginBottom: "0px" }}>
                      {activeContactUsFortheCompayCode?.addressline_1 && activeContactUsFortheCompayCode?.addressline_2
                        ? `${activeContactUsFortheCompayCode?.addressline_1}, ${activeContactUsFortheCompayCode?.addressline_2}`
                        : "Address not available"}
                      <br />
                      {activeContactUsFortheCompayCode?.city &&
                      activeContactUsFortheCompayCode?.district &&
                      activeContactUsFortheCompayCode?.state &&
                      activeContactUsFortheCompayCode?.pincode
                        ? `${activeContactUsFortheCompayCode?.city}, ${activeContactUsFortheCompayCode?.district.trim()}, ${activeContactUsFortheCompayCode?.state} - ${activeContactUsFortheCompayCode?.pincode}`
                        : "Location details not available"}
                    </p>
                  </div>
                </Col> */}

              {/* Phone Section */}
              {/* <Col span={24} className="icons_align">
                <div className="icon">
                  <PhoneOutlined rotate={90} />
                </div>
                <div>
                  {activeContactUsFortheCompayCode?.mobile_number ? (
                    <a
                      href={`tel:${activeContactUsFortheCompayCode.mobile_number}`}
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      {activeContactUsFortheCompayCode.mobile_number}
                    </a>
                  ) : (
                    <p>Phone number not available</p>
                  )}
                </div>
              </Col> */}

              {/* Email Section */}
              {/* <Col span={24} className="icons_align">
                <div className="icon">
                  <MailOutlined />
                </div>
                <div>
                  {activeContactUsFortheCompayCode?.email ? (
                    <a
                      href={`mailto:${activeContactUsFortheCompayCode.email}`}
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      {activeContactUsFortheCompayCode.email}
                    </a>
                  ) : (
                    <p>Email not available</p>
                  )}
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="Footer_Top3">
              {/* {console.log("FooterMap", content_all?.onewear_footer_map)} */}
                  {/* <div className='Footer_map'> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.101777159397!2d72.98628557425593!3d19.234395646982783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9d5e8737a41%3A0xbd231851659a1466!2sLodha%20Signet%2C%20Kolshet%20Road%20Thane!5e0!3m2!1sen!2sin!4v1738751813461!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe src={`${content_all?.onewear_footer_map}`}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15662.360134790662!2d76.945946!3d11.069332000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f63cb18e7145%3A0x90d266e3d150d5a6!2sSreevatsa%20Garden%2C%20Thudiyalur%2C%20Tamil%20Nadu%20641029!5e0!3m2!1sen!2sin!4v1710408624772!5m2!1sen!2sin"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                  {/* </div> */}
            </div>

            {/* <div className="Footer_Top3">
              <p className="Footer_Top3_title">Helpful Links</p>
              <ul className="Footer_Top3_links">
                <li>
                    Contact us
                    </li>
                <li>
                    Privacy Policy
                    </li>
                <li>
                    Terms And Condition
                     </li>
                <li>
                    Cancellation Policy
                     </li>
                <li>
                    Return Policy
                     </li>
                <li>
                    Refund policy
                    </li>
              </ul>
            </div> */}
          </div>
        </div>
        {/* <div className="Footer_Middle">
          <div className="Footer_Middle_Main">
            <div className="Footer_Middle1">
              <p className="Footer_Middle_title">Our Company</p>
              <ul className="Footer_Middle_links">
                <li>About Us </li>
                <li> Media Coverage</li>
                <li>Facebook Page </li>
                <li>Product Review Program </li>
                <li>Customer Reviews </li>
                <li>Gift ideas</li>
              </ul>
            </div>
            <div className="Footer_Middle2">
              <p className="Footer_Middle_title">Help</p>
              <ul className="Footer_Middle_links">
                <li>My Account </li>
                <li>Cancellation & Refund</li>
                <li>Shipping & Delivery Policy </li>
                <li>Terms & Conditions </li>
                <li>Disclaimer Policy </li>
                <li> Privacy Policy</li>
                <li> About Us</li>
              </ul>
            </div>
            <div className="Footer_Middle3">
              <p className="Footer_Middle_title">Occasions</p>
              <ul className="Footer_Middle_links">
                <li>Birthday </li>
                <li>Anniversary</li>
                <li>Wedding </li>
                <li>Friendship Day </li>
                <li>Mother's Day </li>
                <li>Father's Day</li>
                <li>Corporate Events</li>
              </ul>
            </div>
            <div className="Footer_Middle4">
              <p className="Footer_Middle_title">Festivals</p>
              <ul className="Footer_Middle_links">
                <li>Christmas </li>
                <li>New Years 2024</li>
                <li>Bhai Dooj </li>
                <li>Teacher's Day </li>
                <li>Diwali </li>
              </ul>
            </div>
          </div>
        </div> */}
        
      </div>
    </div>
    <div className="Footer_Bottom">
          <div className="Footer_Bottom_Main">
            <p className="Footer_Bottom_title">ONEWEAR © 2024</p>
            <p className="Footer_Bottom_title">
              Designed By
              <Link to="https://ecdigi.com/" target="_blank" style={{textDecoration: "none", color: "#898787"}}>
              {/* <span className="Blazon_Link">&nbsp; */}
                {/* ecdigi */}
                <img src={EcdigiLogo} alt="logo" />
                {/* </span> */}
              </Link>
            </p>
          </div>
        </div>
  </FooterAlign>
  )
}

export default Footer12;

const FooterAlign = styled.div`
background: #ECECEC;
  .Footer_Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Footer_Section .Footer_Top {
    width: 100%;
    /* height: 250px; */
    flex-shrink: 0;
    background: #ECECEC;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main {
    padding: 40px 0 40px 0;
    width: 80%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    justify-content: space-between;
    /* border:1px solid red; */
    margin: auto;
    margin-bottom: 40px;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 {
    /* width: 40%; */
    width: 25%;
    margin-top: 1%;
  }

  .Footer_Top_logo{
    margin-top: 30px;
  }

  .Footer_Top_logo .Footer_Top1{
    /* width: 25%; */
    width: 15%;
    margin-top: 1%;
    margin: 0 auto;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2,
  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 {
    width: 25%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Logo_Image {
    /* border: 1px solid #fff; */
    width: 50%;
    /* height:20% !important; */
    height:auto;
    flex-shrink: 0;
    aspect-ratio: 5/1;
      object-fit: contain;
    /* border: 1px solid red; */

    img{
      width: 100%;
      height: 100%;
      
    }
  }
  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Logo_Image img {
    /* border: 1px solid #fff; */
    width: 100%;
    height:100% !important;
    /* border: 1px solid red; */
  }


  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .content {
    width: 70%;
    /* height: 127px; */
    flex-shrink: 0;
    color: #000000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    text-align: justify;
    /* font-weight: 400; */
    /* line-height: 200%; */
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Online_Card {
    /* width: 60%; */
    width: 50%;
    /* height: 32px; */
    /* flex-shrink: 0; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* gap: 20px; */
    gap: 10px;
    /* border: 1px solid red */
  }

  .Footer_Top_logo .Footer_Top1 .Online_Card{
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .Online_Card .Online {
      padding: 5px;
      /* border: 1px solid blue; */
      border-radius: 50%;
      background-color: #D9D9D9;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .Footer_Top2_Title {
    color: #000000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .Mobile_Number {
    padding-left: 0px;
    color: #000000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2 .svg_Mobile {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 .Footer_Top3_title {
    color: #000000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 .Footer_Top3_links {
    padding-left: 0px;
    color: #000000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Section .Footer_Middle {
    width: 100%;
    flex-shrink: 0;
  }

  .Footer_Middle .Footer_Middle_Main {
    padding: 10px 40px 10px 40px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
    width: 20%;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3 .Footer_Middle_title,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 .Footer_Middle_title {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Middle .Footer_Middle_Main .Footer_Middle1 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle2 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle3 .Footer_Middle_links,
  .Footer_Middle .Footer_Middle_Main .Footer_Middle4 .Footer_Middle_links {
    padding-left: 0px;
    color: #929090;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Bottom {
    width: 100%;
    height: auto;
    padding: 10px 0 0 0;
    flex-shrink: 0;
    background: #313131;
  }

  .Footer_Bottom_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 14px 200px 14px  85px; */
    /* border: 1px solid red; */
    width: 80%;
    margin: auto;
  }

  .Footer_Bottom .Footer_Bottom_Main .Footer_Bottom_title {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 200%;
    display: flex;
    align-items: center;
    height: 30px;
  }

  .Footer_Bottom_title a{
    height: 100%;

    img{
      height: 100%;
      /* background-color: #ffffff; */
      /* margin-left: 2.5px; */
      color: #ffffff;
    }
  }

  .Footer_map{
    /* border: 1px solid red;         */
    /* width: 467px; */
    /* height: 360px; */
    flex-shrink: 0;
  }

  .Footer_Bottom .Footer_Bottom_Main .Blazon_Link {
    padding-left: 0px;
    color: #ffffff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 200%;
  }

  /* .Footer_Top3_title{
    margin: 0px !important;
  } */

  .icons_align {
    display: flex;
    .icon {
      margin-right: 10px;
    }
    p {
      /* margin-bottom: 0px; */
    }
  }

  @media screen and (max-width: 1024px) {
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1{
      /* margin-top: 5% !important; */
      /* width: 100%; */
      /* text-align: left; */
      /* margin-bottom: 20px; */
      /* padding: 10px 10px ; */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    .Footer_Section .Footer_Top .Footer_Top_Main {
      /* display: flex;
      flex-direction: column;
      align-items: center; */
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1{
      margin-top: 5% !important;
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      padding: 10px 10px ;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .content {
      width: 100%;
    }

    /* .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1, */
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2,
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      padding: 10px 10px ;
    }

    .Footer_Middle .Footer_Middle_Main {
      display: flex;
      flex-wrap: wrap;
    }

    .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
    }

    .Footer_Bottom .Footer_Bottom_Main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    }
  }

  @media (max-width: 540px) {
    .Footer_Section .Footer_Top .Footer_Top_Main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Footer_Top_Main{
      width: 90% !important;
    }

    .Footer_Top_logo{
    margin-top: 30px;
  }

  .Footer_Top_logo .Footer_Top1{
    /* width: 25%; */
    width: 82%;
    margin-top: 1%;
    margin: 0 auto;
    padding: 20px 20px 0px 0px;
  }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1 .content {
      width: 100%;
    }

    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top1,
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top2,
    .Footer_Section .Footer_Top .Footer_Top_Main .Footer_Top3 {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      padding: 10px 20px ;
      margin-top: 0% !important;
      align-items: flex-start;
    }

    .Footer_Middle .Footer_Middle_Main {
      display: flex;
      flex-wrap: wrap;
    }

    .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
    }

    .Footer_Bottom .Footer_Bottom_Main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .Footer_Middle .Footer_Middle_Main .Footer_Middle1,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle2,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle3,
    .Footer_Middle .Footer_Middle_Main .Footer_Middle4 {
      width: 50%;
      text-align: center;
      margin-bottom: 20px;

    }
  }
`;