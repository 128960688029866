// App Title
export const app_title = 'ecDigi Technologies';

// One Wear
export const company_code = 'e9237dd0-d58a-426b-b9bb-e89bbee2817a'

// Poornima
// export const company_code = '69488701-2976-4c9e-b1a3-e864db691e76'

// Girls Hub
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg'

// Smile
// export const company_code = '27a39f65-8bc3-4009-8749-417069a7f0ed' 

// Gowri
// export const company_code = 'c6d06571-0b36-439b-95f3-c51bbfe2d6a' 

// Coswan
// export const company_code = 'a3030222-e214-45fd-bc6c-9505289f73f1'

// Local URLs
// export const base_url = 'http://localhost:8008/';
// export const api_admin = 'http://localhost:8008/api/v1/admin/';
// export const api_website = 'http://localhost:8008/api/v1/website/';
// export const web_base_url = 'http://localhost:9000';
// export const web_base_url1 = 'http://localhost:9000';

// Live Common URLs
export const base_url = 'https://admin.ecdigi.com/';
export const api_admin = 'https://admin.ecdigi.com/api/v1/admin/';
export const api_website = 'https://admin.ecdigi.com/api/v1/website/';

// Live One Wear
export const web_base_url = 'https://onewear.in/';
export const web_base_url1 = 'https://onewear.in';

// Live Poornima
// export const web_base_url = 'https://poornimaeshop.com/';
// export const web_base_url1 = 'https://poornimaeshop.com/';

// Live GirlsHub
// export const web_base_url = 'https://mygirlshub.com/';
// export const web_base_url1 = 'https://mygirlshub.com/';

// Live Smile
// export const web_base_url = 'https://shop.smile3denterprises.com/';
// export const web_base_url1 = 'https://shop.smile3denterprises.com/';

// Live Gowri
// export const web_base_url = 'https://www.shop.gowriplates.com/';
// export const web_base_url1 = 'https://www.shop.gowriplates.com/';

// Live Coswan
// export const web_base_url = 'http://103.64.129.68:8471/';
// export const web_base_url1 = 'http://103.64.129.68:8471';
