import React, { useState, useRef, useEffect } from 'react'
import profile from '../../../Assets/Images/coswan/profile.svg';
import favourite from '../../../Assets/Images/coswan/heart.svg';
import cart2 from '../../../Assets/Images/coswan/shoppingBag.svg';
import { SearchOutlined, UpSquareFilled } from '@ant-design/icons';
import styles from './index.module.css';
import Marquee from "react-fast-marquee";
import { cartSuccess } from "../../../Redux/slices/cart";
import {  useSelector,useDispatch } from 'react-redux';
import { Menu, Button, Drawer ,Badge} from 'antd';
import {  MenuOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { wiseListSuccess } from "../../../Redux/slices/wishList";
import { HeartOutlined,HeartFilled, ShoppingOutlined, ShopFilled,UserOutlined } from '@ant-design/icons';
import { Space, Divider,  Popconfirm,  Dropdown, Row, Col, Collapse } from "antd";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import API from "../../../ApiService/ApiService";
import crud_service from "../../../ApiService/CrudService";

const Header14 = ({content_all}) => {
  const {coswan_offer_title,coswanlogo,banner_marquee_text}=content_all
  const my_cart_details = useSelector((state)=>state?.cart?.cart?.data);
  const my_wishList = useSelector((state)=>state?.wishList?.cart?.data);
  const CartLocal = JSON.parse(localStorage.getItem('cartLocal'));
  const wishlistLocal = JSON.parse(localStorage.getItem('wishList'));
  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;
  const location = useLocation(); 
  const userString = localStorage.getItem("user");
  const selectedProducts = useSelector((state) => state?.coswansellerproduct?.selectedProducts);
  const selectedcart=useSelector((state)=>state?.coswansellerproduct?.cart);
  const [cart, setCart] = useState([]);
  const [wiseList, setWiseList] = useState([]);
  
    const dispatch = useDispatch();
  
  let user;
    try {
        user = userString && userString !== "undefined" ? JSON.parse(userString) : "";
    } catch (error) {
        console.error("Error parsing user data:", error);
        user = "";
    }

//  console.log(
//   "userdata",
//   user
//  )
    const data4Checkout = {
       buyer_id: user?._id,
      //  company_code:user
      // ?.company_code,
    };

    const api = new API();
    const crud = new crud_service();
      const getAllCart = () => { 
        console.log("checkapi of allgot parameter",data4Checkout)     
        if (user) {
          api.getcart(data4Checkout).then((res) => {
            if (res.response?.data?.status === 500) {
              console.log("error message",res.response?.data)
              setCart({
                cart_item: [],
              });
            } else {
              console.log("problemin cart data",res?.data?.data)
              setCart(res?.data?.data);
              dispatch(cartSuccess(res?.data));
            }
          });
        }
      };
        const getAllWiseList = () => {
          // api
          //   .getWishList()
          //   .then((res) => {
          //     setWiseList(res?.data?.data);
          //   })
          //   .catch((err) => {
          //     setWiseList([]);
          //   });
          api.getWishList(user?._id).then((res) => {
            setWiseList(res?.data?.data || []);
            dispatch(wiseListSuccess(res?.data));
          });
        };
      
    useEffect(() => {
      getAllCart();
      getAllWiseList();
    }, []);
  
  const [categories,setcategories]=useState([
    {
      category:'Shop by Collection',
      items:['Elegant Classics','Modern Chic','Statement Pieces','Bridal & Wedding','Seasonal Specials','Personalized Creations']
    },
    {
      category:'Shop by Type',
      items:['Necklaces & Pendants','Earrings','Bracelets & Bangles','Rings','Anklets & Toe Rings','Anklets & Toe Rings']
    },
    {
      category:'Shop by Occasion',
      items:['Everyday Wear','Special Occasions','Gifts for Her','Gifts for Him','Anniversary & Engagement','Holiday & Festive']
    },
    {
      category:'Shop by Material',
      items:['Pure Silver','Sterling Silver','Silver with Gemstones','Gold-Plated Silver','Silver with Pearls']
    },
    {
      category:'New Arrivals',
      items:['New Designs','Limited Edition']
    },
    {
      category:'Sale & Offers',
      items:['Discounts & Promotions','Clearance Items','Buy More, Save More','Bridal & Wedding']
    },
    {
      category:'Best Sellers',
      items:['Customer Favorites','Trending Now']
    }])
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product

    const categoryRefs = useRef([]); 
    const [searchVisible, setSearchVisible] = useState(false); 
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        categoryRefs.current.forEach((categoryRef, index) => {
          if (categoryRef && !categoryRef.contains(event.target)) {
            if (selectedCategoryIndex === index) {
              setSelectedCategoryIndex(null); 
            }
          }
        });
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [selectedCategoryIndex]);
  
    const handleCategoryClick = (index) => {
      setSelectedCategoryIndex(selectedCategoryIndex === index ? null : index);
    };

  const toggleSearchBar = () => {
      setSearchVisible(!searchVisible); // Toggle the visibility of the search bar
    };

    const showDrawer = () => setDrawerVisible(true);
    const closeDrawer = () => setDrawerVisible(false);
    const [isHovered, setIsHovered] = useState(null); 

    const handleMenuItemClick = (e) => {
      setSelectedKey(e.key); 
    };
    
    const handleSearch = () => {
      if (search !== "") {
        navigate(`/products?product_name=${search}`);
        setSearchVisible(false); 
      }
    };

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     handleSearch();
  //   }
  // };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setSelectedCategoryIndex(null);
  };
  const handleCategoryHover = (index) => {
    setSelectedCategoryIndex(index); 
  };
  const wrapLastWordInSpan = (text) => {

    const words = text.split(' ');
    const lastWord = words.pop();
    const modifiedText = (
      <>
        {words.join(' ')}{' '}
        <span className="coupancode">{lastWord}</span>
      </>
    );

    return modifiedText;
  };
  const imageurl=process.env.REACT_APP_API_BASE;

  return (
    <div className={`${styles.headerContainer}`}>
      <div className={`${styles.coupanCodeRow}`}>
      <p className="tenor-sans-regular">{wrapLastWordInSpan(coswan_offer_title)}</p>

            {/* <p className='tenor-sans-regular'>BUY 1 GET 1 FREE | Use Code:  <span className="coupancode">COS01</span></p> */}
      </div>
      <div className={`${styles.headerSearchRow}`} >
            <Drawer
              title="Categories"
              placement="left"
              visible={drawerVisible}
              onClose={closeDrawer}
              width={300}
              className={`${styles.customdrawer}`}  
            >
              <Menu
                mode="inline"
                selectedKeys={[selectedKey]}
                onClick={handleMenuItemClick}
                style={{ width: '100%' }}
              >
                {categories.map((item, index) => (
                  <Menu.SubMenu key={index} title={item.category}>
                    {item.items.map((product, idx) => (
                      <Menu.Item key={idx}>{product}</Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ))}
              </Menu>
            </Drawer>
            <div className={`${styles.menulogosearchdiv}`}>

                <div className="menu-toggle-button" style={{display:'flex',alignItems:'center',gap:'15px'}}>
                  <Button className={`${styles.menubtn}`} icon={<MenuOutlined />} onClick={showDrawer} />
                  <Link to="/">
                  <img className={`${styles.websitelogo}`} src={`${imageurl}${coswanlogo?.image}`} loading='lazy' alt='coswan logo'/>
                  </Link>
                
                </div>
                <div className={`${styles.searchbar}`}>
                  <Input
                  placeholder="Search items"
                  prefix={<SearchOutlined />}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e)=>{
                    if(e.key == "Enter"){
                      handleSearch()
                    }
                  }}
                  style={{ outline: 'none',border:'none',width:'100%',height:'100%',borderRadius:'20px' }}
                />        
                </div>
            </div>

            <div className={`${styles.pagenotelogo}`}>
              <div>
                
                      <Button
                        className={`${styles.search_icon_btn}`}
                        icon={<SearchOutlined  style={{ border: 'none', background: 'transparent', fontSize: '24px'
                        }}/>}
                        onClick={toggleSearchBar}
                        style={{ border: 'none', background: 'transparent', fontSize: '19px' }}
                      />
                     {searchVisible && (
                      <div className='searchbaronicon'
                      style={{
                        width:'50vw',
                        zIndex:'1000',
                        position:'absolute',
                        right:'4%',
                        top:'89px',
                        transition: 'transform 0.3s ease', 
                      }}>
                        <Input
                          placeholder="Search items"
                          // prefix={<SearchOutlined />}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => setSearch(e.target.value)}
                          style={{
                            outline: 'none',
                            border: 'none',
                            width: '100%',
                            height: '100%',
                          }}
                          onKeyDown={(e)=>{
                            if(e.key == "Enter"){
                              handleSearch()
                            }
                          }}
                        />
                      </div>
                    )}
              </div>
              <Link to="/my-profile" className="link">
                 <img className={`${styles.profilelogo}`} src={profile} loading='lazy' alt='coswan logo'/>
              </Link>
              
               <div className="WishList">
                    <Link to="/wish-list" style={{ textDecoration: "none" }}>
                    <span className="me-1" style={{ color: "black", textDecoration: "none", fontSize: "small" }}>
                    </span>
                    <Badge
                        color=' #010180'
                        count={my_wishList?.length ? my_wishList?.length : wishlistLocal?.length}
                        className="Cart_Badge"
                      >
                  <img className={`${styles.favlogo}`} src={favourite} loading='lazy' alt='coswan logo' />

                      {/* <svg
                        width="25"
                        height="25"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.1926 4.3867C21.3862 4.3867 18.929 5.58233 17.3973 7.60332C15.8657 5.58233 13.4085 4.3867 10.602 4.3867C8.36799 4.3892 6.22622 5.2695 4.64654 6.83449C3.06687 8.39948 2.1783 10.5213 2.17578 12.7346C2.17578 22.1596 16.2815 29.7884 16.8822 30.1035C17.0406 30.1879 17.2175 30.2321 17.3973 30.2321C17.5771 30.2321 17.7541 30.1879 17.9124 30.1035C18.5131 29.7884 32.6189 22.1596 32.6189 12.7346C32.6163 10.5213 31.7278 8.39948 30.1481 6.83449C28.5684 5.2695 26.4266 4.3892 24.1926 4.3867ZM17.3973 27.9223C14.9157 26.4897 4.35029 19.9635 4.35029 12.7346C4.35244 11.0926 5.0118 9.51847 6.18375 8.35741C7.35571 7.19635 8.9446 6.54313 10.602 6.54099C13.2454 6.54099 15.4647 7.93589 16.3916 10.1764C16.4735 10.3739 16.6129 10.5429 16.7919 10.6618C16.971 10.7807 17.1817 10.8442 17.3973 10.8442C17.6129 10.8442 17.8236 10.7807 18.0027 10.6618C18.1818 10.5429 18.3211 10.3739 18.403 10.1764C19.3299 7.93185 21.5493 6.54099 24.1926 6.54099C25.85 6.54313 27.4389 7.19635 28.6109 8.35741C29.7828 9.51847 30.4422 11.0926 30.4443 12.7346C30.4443 19.9528 19.8763 26.4883 17.3973 27.9223Z"
                          fill="#000000"
                        />
                      </svg> */}
                      </Badge>
                    </Link>
                </div> 
               <div className="Cart">
                                 <Link to="/cart" style={{ textDecoration: "none" }}>
                                   <span className="me-1" style={{ color: "black", textDecoration: "none", fontSize: "small" }}>
                                     {/* Cart                     */}
                                   </span>
                                   <Badge
                                     color=' #010180'
                                     count={(login && token && userData) ? my_cart_details?.cart_item?.length : CartLocal?.length}
                                     className="Cart_Badge"
                                   >
                                    
                                    <img className={`${styles.cartlogo}`} src={cart2} loading='lazy' alt='coswan logo'/>

                                   </Badge>
                                 </Link>
                </div>
          
            </div>
      </div>
      <div className={`${styles.headerCategoryChooseRow}`}>
      {categories.map((item, index) => (
        <div
          className={selectedCategoryIndex === index ? `${styles.categoryproductchoosen}` : `${styles.categoryproduct}`}
          key={index}
          ref={(el) => (categoryRefs.current[index] = el)} 
          onMouseLeave={() => handleCategoryHover(null)}

        >
          <h5 
          onMouseEnter={() => handleCategoryHover(index)}
          onClick={() => handleCategoryClick(index)} className="tenor-sans-regular header">
            {item.category}
          </h5>
          {selectedCategoryIndex === index && (
            <div
              className={`${styles.categoryItems} ${selectedCategoryIndex === index ? `${styles.show}` : ''}`}
              style={{ cursor: 'pointer' }}

            >
              {item.items.map((product, idx) => (
                <div className={`${styles.productItem}`} key={idx}  onClick={() => handleProductClick(product)}>
                  <p className="roboto">{product}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>  
      <div className={`${styles.headerIntroRow} roboto`}>
        <Marquee speed={60} gradient={false}> 
          {banner_marquee_text}
        </Marquee>
      </div>
      
    </div>
  )
}

export default Header14
