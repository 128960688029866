import React from "react";
import styled from "styled-components";
import FooterLogo from "../../Assets/Images/dyuken logo.png";
import { Input, Form, Button, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import facebook from "../../Assets/Images/Template-9/facebook.png";
import instagram from "../../Assets/Images/Template-9/instagram.png";
import linkedin from "../../Assets/Images/Template-9/linkedin.png";
import twitter from "../../Assets/Images/Template-9/twitter.png";
import { Link } from "react-router-dom";

import API from "../../ApiService/ApiService"

const Footer9 = ({company, socialmedia, content_all}) => {
  const api = new API();

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  // console.log("social mediacontent_all---->", content_all)

  const [form] = Form.useForm();

  const onFinish = (values) => {
    api.createCommon("subscriber", values).then((res) => {
      if (res?.status == 201) {
        form.resetFields();
        message.success(res?.data?.message);
      } else {
        message.error("Something went wrong");
      }
    });
  };


  return (
    <FooterAlign>
      <div className="home_wrapper">
        <div className="footer_Main">
          <div className="Footer_Left">
            <div className="Footer_Logo">
              <img src={process.env.REACT_APP_API_BASE + content_all?.dyken_logo_footer?.image} alt="footer-logo" />
              {/* <img src={FooterLogo} alt="footer-logo" /> */}
            </div>
            <div className="Footer_Content">
              <p className="Content">
                {/* Donec mattis porta eros, aliquet finibus risus interdum at.
                Nulla vivethe as it was for us to know what was to be done. the
                this is a long post for the text.`This small text has to be
                place here, since this is. */}
                {content_all?.dyken_footer_dec}
              </p>
            </div>
          </div>
          <div className="Footer_Middle_Left">
            <ul className="List">
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
                <li>About us</li>
              </Link>
              <Link
                to="/products"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
                <li> Product</li>
              </Link>
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
                <li>Contact us</li>
              </Link>
            </ul>
          </div>
          <div className="Footer_Middle">
            <ul className="List">
            <Link
                to="/privacy-policy"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Privacy Policy</li>
              </Link>
              <Link
                to="/delivery-policy"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Delivery Policy</li></Link>
              <Link
                to="/terms-and-condition"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Terms & Condition</li></Link>
              <Link
                to="/refund-policy"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Refund Policy</li></Link>
            </ul>
          </div>
          <div className="Footer_Middle_Right">
            <ul className="List">
            <Link
                to="/return-policy"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Return Policy</li></Link>
              <Link
                to="/cancellation-policy"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Cancel Policy</li></Link>
              <Link
                to="/my-profile"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>My Profile</li></Link>
              <Link
                to="/cart"
                style={{ textDecoration: "none", color: "#6a6a6a" }}
              >
              <li>Cart</li></Link>
            </ul>
          </div>
          <div className="Footer_Right">
            <div className="Right_Title">
              <p className="Title">Subscribe for Newsletter</p>
            </div>
            <div className="Right_Input">
              <Input
                type="text"
                placeholder="E-mail id"
                className="Input"
                suffix={<RightOutlined />}
              />
            </div>
            <div className="Social_Icons">
              <ul className="List">
                {content_all?.dyken_social_media?.map((item, i)=>(
                  <li key={i}>
                  <a target="_blank" href={item?.dyken_media_link}><img src={api.rootUrl1 + item?.dyken_media_img?.image} alt="footer-logo" /></a>
                </li>
                ))}
                {/* <li>
                  <img src={instagram} alt="footer-logo" />
                </li>
                <li>
                  <img src={linkedin} alt="footer-logo" />
                </li>
                <li>
                  <img src={twitter} alt="footer-logo" />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="Footer_Bottom">
          <div className="Left">
            <p className="Title">Copyrights@dyukenfurniture</p>
          </div>
          <div className="Right">
            <p className="Title">
              Web designed by <span className="Blazon">Blazon</span>{" "}
            </p>
          </div>
        </div>
      </div>
    </FooterAlign>
  );
};

export default Footer9;

const FooterAlign = styled.div`
  .footer_Main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 0 100px;
    height: 250px;
  }

  .footer_Main .Footer_Left {
    width: 30%;
  }

  .footer_Main .Footer_Middle_Left {
    width: 10%;
  }

  .footer_Main .Footer_Right {
    width: 30%;
  }

  .Footer_Left .Footer_Logo img {
    width: 150.367px;
    height: 48.664px;
    flex-shrink: 0;
    padding-bottom: 10px;
  }

  .Footer_Left .Footer_Content .Content {
    color: #6a6a6a;
    text-align: justify;
    font-family: Flama Trial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .Footer_Middle_Left .List,
  .Footer_Middle .List,
  .Footer_Middle_Right .List {
    color: #6a6a6a;
    font-family: Flama Trial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .Footer_Right .Right_Title {
    color: #000;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Footer_Right .Right_Input .Input {
    width: 380px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    border: 1px solid #000;
    background-color: transparent;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper
    > input.ant-input {
    padding: 8px;
    font-size: inherit;
    border: 1px solid black;
    outline: none;
    border-radius: 8px 0 0 0;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper
    .ant-input-suffix {
    margin-inline-start: 0 !important;
  }

  .Footer_Right .Social_Icons .List {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
  }

  .Footer_Right .Social_Icons .List li {
    padding: 3px;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    /* background-color: #d9d9d9; */
    display: grid;
    place-items: center;
    border-radius: 50%;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper
    .ant-input-suffix {
    border-radius: 0px 0px 7px 0px;
    background: #000;
    padding: 10px;
    color: #fafafa;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper {
    padding: 0 !important;
    padding-left: 5px;
  }

  .Footer_Bottom {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    background: #0489d6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 80px 0 80px;
  }

  .Footer_Bottom .Left {
    color: #fff;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Bottom .Right {
    color: #fff;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
  }

  .Footer_Bottom .Right .Blazon {
    color: #56c20e;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
  }
`;
