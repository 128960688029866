import React from "react";
import Header7 from "../Header/Header7";
import Header8 from "../Header/Header8";
import Header9 from "../Header/Header9";
import Header10 from "../Header/Header10";
import Header11 from "../Header/Header11";
import Header12 from "../Header/Header12";
import Header13 from "../Header/Header13";
import Header14 from "../Header/header14/Header14";

const HeaderMain = ({
  header_type,
  socialmedia,
  home_custom,
  company,
  content_all,
}) => {

  // header_type = "Theme-3"
  // console.log("home_custom",home_custom)
  // console.log("content_all????",content_all)
  // console.log("header_type????",header_type)
  

  switch (header_type) {
    // case "Theme-1":
    //   return <Header1 socialmedia={socialmedia} />;
    // case "Theme-2":
    //   return <Header2 socialmedia={socialmedia} />;
    // case "Theme-3":
    //   return <Header3 socialmedia={socialmedia} />
    // case "Theme-4":
    //   return <Header4 socialmedia={socialmedia} />;
    // case "Theme-5":
    //   return <Header5 socialmedia={socialmedia} />;
    // case "Theme-6":
    //   return <Header6 socialmedia={socialmedia} />;
    // case "Theme-1":
    //   return <Header7 socialmedia={socialmedia} home_custom={home_custom?.theme_1} company={company} />;
    // case "Theme-2":
    //   return <Header8 socialmedia={socialmedia} home_custom={home_custom?.theme_2} />;
    // case "Theme-3":
    //   return <Header9 socialmedia={socialmedia} home_custom={home_custom?.theme_3?.top_offer} />;
    // case "Theme-4":
    //   return <Header10 socialmedia={socialmedia}  home_custom={home_custom?.theme_4}/>;
    // case "Theme-5":
    //   return <Header11 socialmedia={socialmedia} />;
    case "HeaderWith2layersandSearchBar":
      return <Header7 socialmedia={socialmedia} home_custom={home_custom?.theme_1} company={company} content_all={content_all?.HeaderWith2layersandSearchBar} />; //smile
    case "HeaderWithAdMarquee":
      return <Header8 socialmedia={socialmedia} home_custom={home_custom?.theme_2} content_all={content_all?.HeaderWithAdMarquee} />;  
    case "HeaderWith3layersandSearchBar": //Dyken header
      return <Header9 socialmedia={socialmedia} home_custom={home_custom?.theme_3?.top_offer} content_all={content_all?.HeaderWith3layersandSearchBar} />;
    case "HeaderWithTransparentBackground":
      return <Header10 socialmedia={socialmedia}  home_custom={home_custom?.theme_4} content_all={content_all?.HeaderWithTransparentBackground}/>;  //GH
    case "HeaderWithoutSearchBar":
      return <Header11 socialmedia={socialmedia} />;
    case "HeaderWithWhiteTransparentBackground":
      return <Header12 socialmedia={socialmedia} content_all={content_all?.HeaderWithWhiteTransparentBackground} />; //one wear header
    case "HeaderWithAdOffer":
      return <Header13 socialmedia={socialmedia} content_all={content_all?.HeaderWithAdOffer} />; //one wear header
      case "CoswanHeader":
        return <Header14 content_all={content_all?.CoswanHeader} />;
    default:
      return null;
    // default:
      // return (
      //   <Header10
      //     socialmedia={socialmedia}
      //   />
      // );
  }
};

export default HeaderMain;
