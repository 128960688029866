import styles from './footer14.module.css';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import API from "../../../ApiService/ApiService";
import { message } from "antd";
import { useState } from 'react';

const Footer14=({content_all})=>{
    const api = new API();

     const {coswanemail,coswanphonenumber,coswanoffer,designed_by}=content_all;
   
      const [signupemail,setsignupemail]=useState();
      const [isValidEmail, setIsValidEmail] = useState(true);  

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      const handleInputChange = (e) => {
        setsignupemail(e.target.value); 
        if (emailPattern.test(e.target.value)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      };

      const onFinish = () => {

        if (!isValidEmail) {
          message.error("Please enter a valid email.");
          return; 
        }

        api.createCommon("subscriber", {email:signupemail}).then((res) => {
          if (res?.status == 201) {
            message.success(res?.data?.message);
          } else {
            message.error("Something went wrong");
          }
        });
        setsignupemail('')
      };
    return(
        // <div></div>
        <div className={`${styles.Footer14}`}>
          <div className={`${styles.signupRow}`}>
            <h4 className='tenor-sans-regular'>Get exclusive access to special events & more !</h4>
            <div className={`${styles.SignUpContainer}`}>
                <input
                style={{paddingLeft:"10px"}} 
                value={signupemail} 
                onChange={handleInputChange}
                 />
                  {/* {!isValidEmail && signupemail && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Please enter a valid email ID
                      </p>
                    )} */}
                <button className='montserrat-normal-400' onClick={onFinish}>SIGN UP</button>
            </div>
          </div>
          <div className={`${styles.footerdetails}`}>
                    <div className={`${styles.footercol} footercol1`}>
                        <h4 className='roboto'>SHOP</h4>
                        <p className='montserrat-normal-400'>Jewellery<br></br>Gift Certificates<br></br>Affirm<br></br>Wish List</p>
                    </div>
                    <div className={`${styles.footercol} footercol2`}>
                        <h4 className='roboto'>ABOUT US</h4>
                        <p className='montserrat-normal-400'>100% Guarantee<br></br>Why  Coswan Silvers?<br></br>Careers<br></br>Education</p>
                    </div>
                    <div className={`${styles.footercol} footercol3`}>
                        <h4 className='roboto'>SHOP</h4>
                        <p className='montserrat-normal-400'>Concierge<br></br>Affiliates<br></br>Sell Your Jewellery<br></br>We Buy Gold<br></br>Accessibility</p>
                    </div>
                    <div className={`${styles.footercol} footercol4`}>
                        <h4 className='roboto'>NEED HELP?</h4>
                        <div className={`${styles.needhelp_section}`}>
                             <div>
                             <h6 className={`montserrat-normal-400 ${styles.coswanphonenumbersection}`}>
                              <PhoneOutlined style={{ transform: 'rotate(100deg)' }} />
                              <span style={{ paddingLeft: '3px' }}></span>
                              {coswanphonenumber}
                              {/* +91 12345 6789 */}
                              </h6>
                              <div className={`${styles.coswanphonenumbersection}`}>
                                <p className='montserrat-normal-400'>Order Status<br></br>My Account<br></br>Returns<br></br>Unsubscribe</p>
                            </div>
                             </div>
                           
                              <div>
                              <h6 className='montserrat-normal-400'>
                                <MailOutlined />
                                <span style={{ paddingLeft: '3px' }}></span>
                                {coswanemail} 
                              </h6>
                              <div>
                            <p  className='montserrat-normal-400'>Contact Us<br></br>FAQs<br></br>Privacy Policy<br></br>Shipping</p>
                            </div>
                              </div>
                        </div>
                        {/* <div className='needhelp_section-content'>
                            <div className='coswanphonenumbersection'>
                                <p className='montserrat-normal-400'>Order Status<br></br>My Account<br></br>Returns<br></br>Unsubscribe</p>
                            </div>
                            <div>
                            <p  className='montserrat-normal-400'>Contact Us<br></br>FAQs<br></br>Privacy Policy<br></br>Shipping</p>
                            </div>
                        </div> */}

                    </div>
                    <div className={`${styles.footercol} ${styles.footercol5}`}>
                        <h4>
                            {/* Want Rs 500 in Gift Coupons? */}
                               {coswanoffer}
                        </h4>
                        <h3 className='roboto '>VIP Rewards</h3>
                        <p className='montserrat-normal-400'>Sign up now to get these savings<br></br>
                        and so much more!</p>
                        <button className={`${styles.footercol5button} montserrat-normal-400`}>LEARN MORE</button>
                    </div>
          </div>
          <div className={`${styles.copyrightrow}`}>
            <h5 className='montserrat-normal-400'>
             <a href="https://www.ecdigi.com/" target="_blank" rel="noopener noreferrer">
             {designed_by}</a>
            
            </h5>
          </div>
        </div>
    )
}

export default Footer14